import React from 'react'

const CreateEvaluateur = () => {
  return (
    <div>
      CreateEvaluateur
    </div>
  )
}

export default CreateEvaluateur
