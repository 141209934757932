import React from 'react'

const CreateEtablissment = () => {
  return (
    <div>
      etablissment
    </div>
  )
}

export default CreateEtablissment
